import React from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import Logo from "./logo.png";
import { Box } from "@mui/material";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from "./Header";
import Home from "./Home"; // Page d'accueil
import LandingPage from "./LandingPage";
import Immo from "./Immo";
import Conso from "./Conso";
import Rachat from "./Rachat";
import Hypo from "./Hypo";
import Eco from "./Eco";
import SCI from "./SCI";
import ContactPage from "./Contact";
import Terms from "./Terms";

function App() {
  return (
    <HelmetProvider>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh", // La page occupe toute la hauteur de la fenêtre
        }}
      >
        <Router>
          <Header />
          {/* Contenu principal */}
          <Box sx={{ flexGrow: 1 }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/landing" element={<LandingPage />} />
              <Route path="/immo" element={<Immo />} />
              <Route path="/conso" element={<Conso />} />
              <Route path="/rachat" element={<Rachat />} />
              <Route path="/hypo" element={<Hypo />} />
              <Route path="/éco" element={<Eco />} />
              <Route path="/sci" element={<SCI />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/terms" element={<Terms />} />
              {/* Route de secours pour les pages non trouvées */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Box>
        </Router>
      </Box>
    </HelmetProvider>
  );
}

export default App;
