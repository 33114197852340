import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
//import "./styles.css";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { IoIosMail } from "react-icons/io";
import Footer from "./Footer";

const Eco = () => {
  useEffect(() => {
    document.title = "fCrédit.fr - Contactez-nous";
  }, []);

  const onVerify = (token) => {
    alert("hCaptcha token", token);
  };

  const [formData, setFormData] = useState({
    nom: "",
    prénom: "",
    email: "",
    tél: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [selectedValue, setSelectedValue] = useState("RP");

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("a.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formData).toString(),
      });

      const result = await response.text();
      if (result.trim() === "ok") {
        alert("Votre message a été envoyé!");
        setFormData({ name: "", email: "", subject: "", message: "" });
      } else if (result.trim() === "!ok") {
        alert("Une erreur s'est produite, veuillez réessayer.");
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <script src="https://js.hcaptcha.com/1/api.js" async defer></script>
      <Container maxWidth="sm" sx={{ textAlign: "center", mt: 4 }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            fontFamily: "'Libre Baskerville Bold', serif",
          }}
        >
          Contactez-nous
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            mt: 3,
            width: "100%", // Prendre toute la largeur
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: "center", width: "80%" }}
          >
            <div class="form-group">
              <label for="nom">Nom: </label>
              <input type="text" id="nom" name="nom" required />
            </div>
            <div class="form-group">
              <label for="prenom">Prénom: </label>
              <input type="text" id="prenom" name="prénom" required />
            </div>
            <div class="form-group">
              <label for="projet">Projet: </label>
              <select id="projet" name="projet" required>
                <option value="">-- Sélectionnez un projet --</option>
                <option value="RP">Résidence principale</option>
                <option value="RS">Résidence secondaire</option>
                <option value="Investissement">Investissement</option>
              </select>
            </div>
            <div class="form-group">
              <label for="projet1">Choisissez votre projet :</label>
              <label>
                <input
                  type="radio"
                  name="projet1"
                  value="RP"
                  //checked
                  //checked={projet === "RP"}
                  onChange={handleRadioChange}
                />
                Résidence principale
              </label>
              <label>
                <input
                  type="radio"
                  name="projet1"
                  value="RS"
                  //checked={projet === "RS"}
                  onChange={handleRadioChange}
                />
                Résidence secondaire
              </label>
            </div>
            <div class="form-group">
              <label for="montant">Montant: </label>
              <input type="text" id="montant" name="montant" required />
            </div>
            <div class="form-group">
              <label for="projet1">Co-emprunteur: </label>
              <label>
                <input
                  type="radio"
                  name="projet1"
                  value="RP"
                  checked
                  //checked={projet === "RP"}
                  onChange={handleRadioChange}
                />
                Non
              </label>
              <label>
                <input
                  type="radio"
                  name="projet1"
                  value="RS"
                  //checked={projet === "RS"}
                  onChange={handleRadioChange}
                />
                Oui
              </label>
            </div>
            <div class="form-group">
              <label for="email">Email: </label>
              <input type="email" id="email" name="email" required />
            </div>
            <div class="form-group">
              <label for="tél">Téléphone 1: </label>
              <input type="tel" id="tél" name="tél" required />
            </div>
            <div class="form-group">
              <label for="tél">Téléphone 2: </label>
              <input type="tel" id="tél2" name="tél2" required />
            </div>
            {/* Intégration du hCaptcha */}
            <Grid item xs={12}>
              <form>
                <HCaptcha
                  sitekey="2eb45b32-ee72-4486-8db0-6d0c763c1030"
                  onVerify={onVerify}
                />
              </form>
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: "#2128f5" }}
              >
                Envoyer
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default Eco;
