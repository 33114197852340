import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import "./styles.css";
import { IoIosMail } from "react-icons/io";
import Footer from "./Footer";

const Immo = () => {
  useEffect(() => {
    document.title = "fCrédit.fr - Crédit immobilier";
  }, []);

  const [formData, setFormData] = useState({
    type: "immo",
    nom: "",
    prenom: "",
    projet: "",
    montant: "",
    co: "non",
    email: "",
    tel: "",
    tel2: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData, // Copie de l'état précédent
      [name]: value, // Mise à jour du champ modifié
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("u.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formData).toString(),
      });

      //alert(new URLSearchParams(formData).toString());
      const res = await response.json();
      if (res.s === true) {
        alert("Votre demande a été envoyée!");
        setFormData((prev) => ({
          ...prev,
          type: "immo",
          nom: "",
          prenom: "",
          projet: "",
          montant: "",
          email: "",
          tel: "",
          tel2: "",
          co: "non",
        }));
      } else if (res.s === false) {
        alert("Une erreur s'est produite, veuillez réessayer.");
      }
    } catch (error) {
      alert("Une erreur s'est produite, veuillez réessayer.");
    }
  };

  return (
    <>
      <Helmet>
        <title>fCrédit.fr - Crédit immobilier</title>
        <meta
          name="description"
          content="crédit immobillier 100% en ligne, simulateur crédit immobillier"
        />
      </Helmet>
      <Container maxWidth="sm" sx={{ textAlign: "center", mt: 4 }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            fontFamily: "'Libre Baskerville Bold', serif",
          }}
        >
          Contactez-nous
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            mt: 3,
            width: "100%", // Prendre toute la largeur
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: "center", width: "80%" }}
          >
            <div class="form-group">
              <label for="nom">Nom: </label>
              <input
                type="text"
                id="nom"
                name="nom"
                value={formData.nom}
                onChange={handleChange}
                required
              />
            </div>
            <div class="form-group">
              <label for="prenom">Prénom: </label>
              <input
                type="text"
                id="prenom"
                name="prenom"
                value={formData.prenom}
                onChange={handleChange}
                required
              />
            </div>
            <div class="form-group">
              <label for="projet">Projet: </label>
              <select
                id="projet"
                name="projet"
                value={formData.projet}
                onChange={handleChange}
                required
              >
                <option value="">-- Choisissez une option --</option>
                <option value="RP">Résidence principale</option>
                <option value="RS">Résidence secondaire</option>
                <option value="Investissement">Investissement</option>
              </select>
            </div>
            <div class="form-group">
              <label for="montant">Montant: </label>
              <input
                type="text"
                id="montant"
                name="montant"
                value={formData.montant}
                onChange={handleChange}
                required
              />
            </div>
            <div class="form-group">
              <label for="co">Co-emprunteur: </label>
              <label>
                <input
                  type="radio"
                  name="co"
                  value="non"
                  checked={formData.co === "non"}
                  onChange={handleChange}
                  required
                />
                Non
              </label>
              <label>
                <input
                  type="radio"
                  name="co"
                  value="oui"
                  onChange={handleChange}
                />
                Oui
              </label>
            </div>
            <div class="form-group">
              <label for="email">Email: </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div class="form-group">
              <label for="tel">Téléphone 1: </label>
              <input
                type="tel"
                id="tel"
                name="tel"
                value={formData.tel}
                onChange={handleChange}
                required
              />
            </div>
            <div class="form-group">
              <label for="tel2">Téléphone 2: </label>
              <input
                type="tel"
                id="tel2"
                name="tel2"
                value={formData.tel2}
                onChange={handleChange}
              />
            </div>
            {/* Intégration du hCaptcha */}
            <Grid item xs={12} sx={{ mt: 0 }}>
              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: "#2128f5" }}
              >
                Envoyer
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default Immo;
