// Home.js
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Header from "./Header";
import { Box, Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { Container, Typography } from "@mui/material";

function Home() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState(""); // Stocker le nom de l'utilisateur

  useEffect(() => {
    //document.title = "fCrédit.fr - Acceuil";
  }, []);
  return (
    <>
      <Helmet>
        <title>fCrédit.fr - Trouver Votre Prêt 100% en ligne</title>
        <meta
          name="description"
          content="Crédit Immobilier, Crédit à la Consommation, Rachat de crédit, Prêt Viager / Hypothécaire 100% en ligne. Mise en relation et proposition en 48h"
        />
      </Helmet>
      <div>
        {!isLoggedIn ? (
          <>
            <div align="center">
              <p>
                <h2>Trouver votre prêt 100% en ligne</h2>
              </p>
              <p>Mise en relation et proposition en 48h*⏳🚀</p>
            </div>
            <Container maxWidth="sm" sx={{ textAlign: "center", mt: 3 }}>
              <Box
                component="form"
                //onSubmit={handleSubmit}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 2,
                  mt: 3,
                  width: "100%",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{ justifyContent: "center", width: "100%" }}
                >
                  <Grid item xs={6}>
                    <Button
                      component={Link}
                      to="/immo"
                      variant="contained"
                      style={{
                        minHeight: "3.75rem",
                        backgroundColor: "primary",
                      }}
                      fullWidth
                    >
                      Crédit Immobilier
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      component={Link}
                      to="/conso"
                      variant="contained"
                      color="secondary"
                      style={{ minHeight: "3.75rem" }}
                      //style={{ backgroundColor: "#ff00bf" }}
                      fullWidth
                    >
                      Crédit Conso
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      component={Link}
                      to="/rachat"
                      variant="contained"
                      style={{
                        minHeight: "3.75rem",
                        backgroundColor: "#ffed00",
                        color: "black",
                        minHeight: "3.75rem",
                      }}
                      fullWidth
                    >
                      Rachat de crédit
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      component={Link}
                      to="/hypo"
                      variant="contained"
                      style={{
                        minHeight: "3.75rem",
                        backgroundColor: "#FF7A00",
                        fontSize: "0.8em",
                        color: "#fffff",
                      }}
                      fullWidth
                    >
                      Prêt Viager OU Hypothécaire
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </>
        ) : (
          <div></div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default Home;
