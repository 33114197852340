import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
//import "./styles.css";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { IoIosMail } from "react-icons/io";
import Footer from "./Footer";

const Conso = () => {
  useEffect(() => {
    document.title = "fCrédit.fr - Crédit Consommation";
  }, []);

  const [formData, setFormData] = useState({
    type: "conso",
    nom: "",
    prenom: "",
    montant: "",
    duree: "",
    email: "",
    tel: "",
    tel2: "",
    co: "non",
  });

  const updateDurations = (montant) => {
    let availableDurations = [];
    montant = parseFloat(montant);

    if (montant >= 3001 && montant <= 3999) {
      availableDurations = [
        "de 1 à 12 mois",
        "de 13 à 24 mois",
        "de 25 à 36 mois",
        "de 37 à 48 mois",
      ];
    } else if (montant >= 4000 && montant <= 5999) {
      availableDurations = [
        "de 1 à 12 mois",
        "de 13 à 24 mois",
        "de 25 à 36 mois",
        "de 37 à 48 mois",
        "de 49 à 60 mois",
      ];
    } else if (montant >= 6000 && montant <= 25000) {
      availableDurations = [
        "de 1 à 12 mois",
        "de 13 à 24 mois",
        "de 25 à 36 mois",
        "de 37 à 48 mois",
        "de 49 à 60 mois",
        "de 61 à 72 mois",
      ];
    } else if (montant >= 25001 && montant <= 40000) {
      availableDurations = [
        "de 1 à 12 mois",
        "de 13 à 24 mois",
        "de 25 à 36 mois",
        "de 37 à 48 mois",
        "de 49 à 60 mois",
        "de 61 à 72 mois",
        "de 73 à 84 mois",
      ];
    } else if (montant >= 40001 && montant <= 75000) {
      availableDurations = [
        "de 1 à 12 mois",
        "de 13 à 24 mois",
        "de 25 à 36 mois",
        "de 37 à 48 mois",
        "de 49 à 60 mois",
        "de 61 à 72 mois",
        "de 73 à 84 mois",
        "de 85 à 96 mois",
        "de 97 à 108 mois",
        "de 109 à 120 mois",
      ];
    }
    setDurations(availableDurations);
    setSelectKey((prevKey) => prevKey + 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: value,
      };

      if (name === "montant") {
        if (value === "" || isNaN(value) || value < 0) {
          setDurations([]);
        } else {
          updateDurations(value);
        }
      }

      return updatedFormData;
    });
  };

  const [durations, setDurations] = useState([]);
  const [selectKey, setSelectKey] = useState(0);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("u.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formData).toString(),
      });

      const result = await response.json();
      if (result.s === true) {
        alert("Votre demande a été envoyée!");
        setFormData((prev) => ({
          ...prev,
          type: "conso",
          nom: "",
          prenom: "",
          montant: "",
          duree: "",
          email: "",
          tel: "",
          tel2: "",
          co: "non",
        }));
      } else if (result.s === false) {
        alert("Une erreur s'est produite, veuillez réessayer.");
      }
    } catch (error) {
      alert("Une erreur s'est produite, veuillez réessayer.");
    }
  };

  return (
    <>
      <Helmet>
        <title>fCrédit.fr - Crédit Consommation</title>
        <meta
          name="description"
          content="crédit conso 100% en ligne, simulateur crédit conso"
        />
      </Helmet>
      <script src="https://js.hcaptcha.com/1/api.js" async defer></script>
      <Container maxWidth="sm" sx={{ textAlign: "center", mt: 4 }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            fontFamily: "'Libre Baskerville Bold', serif",
          }}
        >
          Contactez-nous
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            mt: 3,
            width: "100%", // Prendre toute la largeur
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: "center", width: "80%" }}
          >
            <div class="form-group">
              <label for="nom">Nom: </label>
              <input
                type="text"
                id="nom"
                name="nom"
                value={formData.nom}
                onChange={handleChange}
                required
              />
            </div>
            <div class="form-group">
              <label for="prenom">Prénom: </label>
              <input
                type="text"
                id="prenom"
                name="prenom"
                value={formData.prenom}
                onChange={handleChange}
                required
              />
            </div>
            <div class="form-group">
              <label for="montant">Montant: </label>
              <input
                type="number"
                id="montant"
                max="75000"
                name="montant"
                value={formData.montant}
                onChange={handleChange}
                required
              />
            </div>
            <div class="form-group">
              <label for="duree">Durée souhaitée: </label>
              <select
                id="duree"
                name="duree"
                key={selectKey}
                value={formData.duree}
                onChange={handleChange}
              >
                <option value="">
                  {durations.length === 0
                    ? "Mettre le montant pour choisir"
                    : "-- Choisissez une option --"}
                </option>
                {durations.map((duration, index) => (
                  <option key={index} value={duration}>
                    {duration}
                  </option>
                ))}
              </select>
            </div>
            <div class="form-group">
              <label for="co">Co-emprunteur: </label>
              <label>
                <input
                  type="radio"
                  name="co"
                  value="non"
                  checked={formData.co === "non"}
                  onChange={handleChange}
                  required
                />
                Non
              </label>
              <label>
                <input
                  type="radio"
                  name="co"
                  value="oui"
                  onChange={handleChange}
                />
                Oui
              </label>
            </div>
            <div class="form-group">
              <label for="email">Email: </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div class="form-group">
              <label for="tel">Téléphone 1: </label>
              <input
                type="tel"
                id="tel"
                name="tel"
                value={formData.tel}
                onChange={handleChange}
                required
              />
            </div>
            <div class="form-group">
              <label for="tel2">Téléphone 2: </label>
              <input
                type="tel"
                id="tel2"
                name="tel2"
                value={formData.tel2}
                onChange={handleChange}
              />
            </div>
            {/* Intégration du hCaptcha */}
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: "#2128f5" }}
              >
                Envoyer
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default Conso;
