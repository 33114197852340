// Footer.js
import React, { useState } from "react"; // useState pour gérer l'état local
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MyImage from "./ionos.png";
import { useLocation, Link as RouterLink } from "react-router-dom";
import Fade from "@mui/material/Fade";

function Footer() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const myParam = queryParams.get("l");

  const [openSplash, setOpenSplash] = useState(false);

  const handleSplashOpen = () => setOpenSplash(true);

  const handleSplashClose = () => {
    setOpenSplash(false);
  };
  return (
    <Box
      sx={{
        mt: 2,
        padding: "16px",
        backgroundColor: "#2128f5",
        textAlign: "center",
        //position: "fixed",
        //bottom: 0,
        //width: "100%",
        //zIndex: 1000,
        borderTop: "1px solid #e0e0e0",
      }}
    >
      <Typography variant="body1" color="#fff">
        &copy; {new Date().getFullYear()} fCrédit.fr
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", gap: "16px" }}>
        {useLocation().pathname === "/landing" || myParam === "yes" ? (
          <>
            <a
              href="mailto:xn--fcrdit-dva@outlook.fr"
              style={{
                textDecoration: "underline",
                textDecorationColor: "#fff",
                color: "#fff",
                marginLeft: "8px",
              }}
            >
              Contact
            </a>
            <RouterLink
              to=""
              onClick={handleSplashOpen}
              style={{
                textDecoration: "underline",
                textDecorationColor: "#fff",
                color: "#fff",
              }}
            >
              Mentions légales
            </RouterLink>
          </>
        ) : (
          <>
            <RouterLink
              to="/contact"
              style={{
                textDecoration: "underline",
                textDecorationColor: "#fff",
                color: "#fff",
                marginLeft: "8px",
              }}
            >
              Contact
            </RouterLink>
            <RouterLink
              to="/terms"
              style={{
                textDecoration: "underline",
                textDecorationColor: "#fff",
                color: "#fff",
              }}
            >
              Mentions légales
            </RouterLink>
          </>
        )}
      </Box>
      <Dialog
        open={openSplash}
        onClose={handleSplashClose}
        TransitionComponent={Fade}
        transitionDuration={300}
        PaperProps={{
          sx: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            //backgroundColor: "rgba(33, 40, 245, 0.7)", // Arrière-plan avec opacité de 0.8
            color: "white",
            p: 4,
          },
        }}
        BackdropProps={{
          sx: {
            backdropFilter: "blur(10px)", // Effet de flou sur l'arrière-plan
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleSplashClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "#ff0000",
            zIndex: 1, // Assure que le bouton reste au-dessus du contenu
          }}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
        <DialogContent>
          <Typography variant="h6" sx={{ color: "black" }} gutterBottom>
            Jeremy FISCAN
            <br />
            61 rue de Lyon
            <br />
            75012 Paris
            <br />
            RCS n°: 984232355
          </Typography>
          <Box sx={{ color: "black" }}>
            vous mets en relation avec des courtiers pour trouver votre prêt
            100% en ligne.
            <p>
              fCrédit.fr peut transmettre des leads aux sociétés suivantes:
              <ul>
                <li>
                  CIB Finance
                  <br />
                  4 rue Camille de Rochetaillée – BP 267
                  <br />
                  42016 Saint-Étienne Cedex
                </li>
              </ul>
            </p>
          </Box>
          <Typography
            variant="h4"
            style={{
              fontFamily: "'Big Shoulders Display Medium', sans-serif",
              color: "#2128f5",
              textDecoration: "underline",
            }}
          >
            Hébergement :
          </Typography>
          <Typography variant="h6" sx={{ color: "black" }}>
            7 Place de la Gare
            <br />
            57200 Sarreguemines
          </Typography>
          <img
            src={MyImage}
            style={{
              width: "20%",
              height: "auto",
              marginTop: "8px",
              marginBottom: "8px",
            }}
          />
          <Typography
            variant="h4"
            style={{
              fontFamily: "'Big Shoulders Display Medium', sans-serif",
              //jaune color: "#ffed00",
              //bleu ciel color: "#00f0ff",
              //vert canva color: "#05ff00",
              color: "#00ff0a",
              textDecoration: "underline",
            }}
          >
            Directeur de la publication :
          </Typography>
          <Typography variant="h6" sx={{ color: "black" }}>
            Jeremy FISCAN
            <br />
            xn--fcrdit-dva@outlook.fr
          </Typography>
          <Button
            variant="contained"
            style={{ backgroundColor: "#2128f5" }}
            onClick={handleSplashClose}
            sx={{ mt: 2 }}
          >
            Fermer les mentions légales
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Footer;
