import React, { useState } from "react";
import { useLocation, Link as RouterLink } from "react-router-dom";
import {
  Toolbar,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Dialog,
  DialogContent,
  Fade,
  Slider,
  TextField,
} from "@mui/material";

import { Menu as MenuIcon, Close as CloseIcon } from "@mui/icons-material"; // Importer les icônes avec alias si nécessaire

import "./fonts.css";

function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSplash, setOpenSplash] = useState(false); // État pour le splash screen

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSimulateurClick = () => {
    setOpenSplash(true);
    handleMenuClose();
  };

  const handleSplashClose = () => {
    setOpenSplash(false);
    setMontant(null);
    setDurée(null);
    setMensualité(null);
    setCoûtTotal(null);
  };

  const [montant, setMontant] = useState(null);
  const [durée, setDurée] = useState(null);
  const [mensualité, setMensualité] = useState(null);
  const [coûtTotal, setCoûtTotal] = useState(null);
  const taux = 0.0398; // Exemple de taux d'intérêt de 3,98%

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "montant") setMontant(value);
    if (name === "durée") setDurée(value);

    if (!value || value === "") {
      setMensualité(null);
      setCoûtTotal(null);
      return;
    }

    // Calcul seulement si les deux valeurs sont présentes
    if (
      (name === "montant" ? value : montant) &&
      (name === "durée" ? value : durée)
    ) {
      const montantValue = parseFloat(
        parseFloat(name === "montant" ? value : montant).toFixed(2),
      );
      const duréeValue = parseFloat(
        parseFloat(name === "durée" ? value : durée).toFixed(2),
      );
      if (isNaN(montantValue) || isNaN(duréeValue)) {
        setMensualité(null);
        setCoûtTotal(null);
        return;
      }

      if (montantValue && duréeValue) {
        const durée1 = duréeValue / 12;
        const mensualité =
          (montantValue * (taux / 12)) /
          (1 - Math.pow(1 + taux / 12, -12 * durée1));
        const coûtTotal = mensualité * duréeValue;

        setMensualité(mensualité.toFixed(2));
        setCoûtTotal(coûtTotal.toFixed(2));
      } else {
        setMensualité(null);
        setCoûtTotal(null);
      }
    }
  };

  const query = new URLSearchParams(useLocation().search);
  const lParam = query.get("l");
  const header = (
    <Typography
      variant="h5"
      style={{
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        display: "flex",
        alignItems: "center",
      }}
      sx={{
        fontFamily: "'Libre Baskerville', serif !important",
        color: "#FFBD59 !important",
        textShadow: `
              0 0 5px #FFBD59 !important,
              0 0 10px #FFBD59 !important,
              0 0 15px #FFBD59 !important,
              0 0 20px #FFBD59 !important,
              0 0 25px #FFBD59 !important,
              0 0 30px #FFBD59 !important,
              0 0 35px #FFBD59 !important
              `,
      }}
    >
      fCrédit.fr
    </Typography>
  );
  return (
    <Box
      sx={{
        backgroundColor: "#2128f5",
        width: "100%",
        position: "relative",
        top: 0,
      }}
    >
      <Toolbar
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          edge="start"
          sx={{ color: "white" }}
          aria-label="menu"
          onClick={handleMenuClick}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleSimulateurClick}>Simulateur</MenuItem>
        </Menu>
        {useLocation().pathname === "/landing" || lParam === "yes" ? (
          <>
            {header}
            <Dialog
              open={openSplash}
              onClose={handleSplashClose}
              TransitionComponent={Fade}
              transitionDuration={300}
              PaperProps={{
                sx: {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  //backgroundColor: "rgba(33, 40, 245, 0.7)", // Arrière-plan avec opacité de 0.8
                  color: "white",
                  p: 4,
                },
              }}
              BackdropProps={{
                sx: {
                  backdropFilter: "blur(10px)", // Effet de flou sur l'arrière-plan
                },
              }}
            >
              <IconButton
                aria-label="close"
                onClick={handleSplashClose}
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  color: "#ff0000",
                  zIndex: 1, // Assure que le bouton reste au-dessus du contenu
                }}
              >
                <CloseIcon fontSize="large" />
              </IconButton>
              <DialogContent>
                <Typography
                  variant="h6"
                  sx={{ color: "black", textAlign: "center" }}
                  gutterBottom
                >
                  Simulateur
                </Typography>
                <TextField
                  label="Montant"
                  variant="outlined"
                  name="montant"
                  value={montant}
                  onChange={handleChange}
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderWidth: "2px",
                        borderStyle: "solid",
                        borderColor: "#000",
                      },
                    },
                    //mb: 2,
                  }}
                />

                <TextField
                  label="Durée en mois"
                  variant="outlined"
                  name="durée"
                  value={durée}
                  onChange={handleChange}
                  fullWidth
                  sx={{ mt: 2 }}
                />
                {mensualité && coûtTotal && (
                  <>
                    <Typography
                      variant="h6"
                      sx={{ color: "black", mt: 2 }}
                      gutterBottom
                    >
                      Mensualité: {mensualité} €
                    </Typography>
                    <Typography variant="h6" sx={{ color: "black" }}>
                      Coût Total du crédit: {coûtTotal} €
                    </Typography>
                  </>
                )}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#2128f5" }}
                  onClick={handleSplashClose}
                  sx={{ mt: 2 }}
                >
                  Fermer le simulateur
                </Button>
              </DialogContent>
            </Dialog>
          </>
        ) : (
          <RouterLink
            to="/"
            style={{
              textDecoration: "none",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              display: "flex",
              alignItems: "center",
            }}
          >
            {header}
          </RouterLink>
        )}
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            margin: 0,
            padding: 0,
          }}
        ></div>
      </Toolbar>
    </Box>
  );
}

export default Header;
